import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'deviceTypeDetails/';

export interface IDeviceTypeDetail {
  id: number;
  idDeviceType: number;
  dataField: string;
  dataName: string;
  dataNameEng: string;
  dataEnable: boolean;
  dataToShow: boolean;
  dataToExport: boolean;
  dataToSend: boolean;
  dataUM: string;
  postProcessAction: string;
  enablePostProcess: boolean;
}

const deviceTypeDetailInfoApi = createApi({
  tagTypes: ['DeviceTypeDetail','Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'deviceTypeDetailsReducer',
  endpoints: (build) => ({
    getAllDeviceTypesDetail: build.query<IDeviceTypeDetail[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeDetail','Dashboard']
    }),
    getAllDeviceTypesDetailByIdDeviceType: build.query<IDeviceTypeDetail[], number>({
      query: (idDeviceType) => routeRoot.concat(`/${idDeviceType}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeDetail','Dashboard']
    }),
    getAllDeviceTypesDetailToShowByIdDeviceType: build.query<IDeviceTypeDetail[], number>({
      query: (idDeviceType) => routeRoot.concat(`/Show/${idDeviceType}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeDetail','Dashboard']
    }),
    getDeviceTypesDetailById: build.query<IDeviceTypeDetail, number>({
      query: (id) => routeRoot.concat(`/id/${id}`),
      keepUnusedDataFor: 120,
      providesTags: ['DeviceTypeDetail','Dashboard']
    }),
    addDeviceTypeDetail: build.mutation<any, { body: Partial<IDeviceTypeDetail> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['DeviceTypeDetail','Dashboard'],
    }),
    updateDeviceTypeDetail: build.mutation<any, { body: Partial<IDeviceTypeDetail>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['DeviceTypeDetail','Dashboard'],
    }),
    deleteDeviceTypeDetail: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['DeviceTypeDetail','Dashboard'],
    })
  }),
});

export default deviceTypeDetailInfoApi;

export const {
  useGetAllDeviceTypesDetailQuery,
  useGetAllDeviceTypesDetailByIdDeviceTypeQuery,
  useGetAllDeviceTypesDetailToShowByIdDeviceTypeQuery,
  useAddDeviceTypeDetailMutation,
  useUpdateDeviceTypeDetailMutation,
  useDeleteDeviceTypeDetailMutation,
} = deviceTypeDetailInfoApi;